#photo{
    /* height:215px; */
    width: 191px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    margin:auto;
}
#photo{
    margin: auto;
}

.proName{
    text-align:center;
    width: 191px;
    margin:auto;
    margin-top: 30px;
    
}

@media (max-width:800px){
    #photo{
        /* height: 90px; */
        width: 150px;
        border-radius: 5px;
        margin:auto;
    }

    .proName{
        width:150px;
        /* margin:auto; */
    }
}