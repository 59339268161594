#Works{
    background-color:#fff0bc6e;
    /* height: 900px; */
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 50px;
}

.title{
    text-align: center;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width:320px){
    #Works{
        /* height: 1500px; */
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width:321px) and (max-width:530px){
    #Works{
        padding-left: 0px;
        padding-right: 0px;
        /* height: 1300px; */
    }
}


@media (min-width:531px) and (max-width:700px){
    #Works{
        padding-left: 75px;
        padding-right: 75px;
        /* height: 1200px; */
    }
}


@media (min-width:701px) and (max-width:800px){
    #Works{
        padding-left: 75px;
        padding-right: 75px;
        /* height: 1000px; */
    }
}