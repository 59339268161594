.researchInfo{
    padding-left:20px;
    padding-right: 20px;
}

.researchTitle{
    padding-top: 60px;
    text-align: center;
}

@media (min-width:801px){
    .researchTitle{
        padding-top: 0px;
        text-align: left;
        width: 200px;
    }

    .researchInfo{
        padding-left: 0px;
    }

    .item{
        padding-left: 0px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}