#footer{
    width: 100%;
    height: 150px;
    margin-top: 30px;
    background-color: #E0E0E0;
    padding-top: 50px;
}

#footer h4{
    display:inline-block;
}

#poweredBy{
    width: 30%;
    margin: auto;
    text-align: center;
}

#copyright{
    width: 50%;
    margin: auto;
    text-align: center;
}

@media (max-width:800px){
    #poweredBy{
        width: 100%;
    }
    #copyright{
        width: 100%;
    }
}