#research{
    background-color:#fff0bc6e;
    text-align: center;
    /* width: 100%; */
    /* height: 450px; */
}


@media (max-width:320px){
    #research{
        height: 700px;
    }
}

@media (min-width:321px) and (max-width:551px){
    #research{
        /* height: 600px; */
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media (min-width:552px) and (max-width:800px){
    #research{
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media(min-width:801px){
    #research{
        padding-left: 25%;
        padding-right: 25%;
    }
}

.mid{
    border-right: 1px solid lightgrey;
}



.icon{
    display: inline-block;
}

.description{
    display: inline-block;
    margin-left: 30px;
}

#researchTitle{
    text-align: center;
    height: 150px;
    padding-top: 50px;
}

.horizontalImg figcaption{
    margin-top: 23px;
}

.description_text{
    text-align: left;
}

.wbackground{
    background-color: white;
}