.student.img{
    border-radius: 50%;
}

.student{
    /* width: 150px; */
    text-align: center;
    margin: auto;
}

@media (max-width:800px){
    .student{
        /* width: 50px; */
    }
}


.name{
    margin-top: 30px;
}
